import React from "react"
import { colors } from "../styles/globals"

const styles = {
  tabStyle: {
    // float: "left",
    flex: 1,
    textAlign: "center",
    lineHeight: "56px",
    color: "white",
    fontSize: 20,
  },
}

export default function Tabs({ setTabValue, tabValue, tabs }) {
  return (
    <div className="templateScreenTabsContainer">
      {tabs.map((item, index) => (
        <div
          className={
            "templateScreenTab" +
            (tabValue !== index ? " subMenuHover" : " templateScreenTabActive")
          }
          key={index}
          // style={{
          //   ...styles.tabStyle,
          //   backgroundColor:
          //     tabValue === index ? colors.secondGray : colors.mainGray,
          //   cursor: tabValue === index ? "default" : "pointer",
          // }}
          onClick={
            tabValue === index
              ? undefined
              : () => {
                  setTabValue(index)
                }
          }
        >
          {item}
        </div>
      ))}

      {/* <div
        style={{
          ...styles.tabStyle,
          backgroundColor: tabValue === 1 ? colors.secondGray : colors.mainGray,
          cursor: tabValue === 1 ? "default" : "pointer",
        }}
        onClick={
          tabValue === 1
            ? undefined
            : () => {
                setTabValue(1)
              }
        }
      >
        Rapoarte
      </div> */}
    </div>
  )
}
