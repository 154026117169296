import React from "react"
import { Helmet } from "react-helmet"
import svgs from "../../../components/Dash/svgs"
import InfoMsg, { InfoMsgType } from "../../../components/InfoMsg"
import Tabs from "../../../components/Tabs"
import TemplatePage from "../../../components/TemplatePage"
import { getAllUrlParams } from "../../../utils"
import {
  FaqArray,
  FaqItem,
  getAllFaqs,
  getFaqById,
} from "../../../utils/api/faqs"
import { PageProps } from "gatsby"

export default function Faq({ location }: PageProps) {
  const [tabValue, setTabValue] = React.useState<number>(0)
  const [selectedFaq, setSelectedFaq] = React.useState<FaqItem | null>(null)
  const [items, setItems] = React.useState<FaqArray>([])
  // const [isLoading, setIsLoading] = useState(true)
  const [infoMsg, setInfoMsg] = React.useState<InfoMsgType>({
    isLoading: true,
    msg: "Vă rugăm așteptați",
  })

  React.useEffect(() => {
    const getFaqs = async () => {
      try {
        const apiResponse = await getAllFaqs()
        if (apiResponse.type) {
          setItems(apiResponse.data)
        }
        setInfoMsg({ isLoading: false })
      } catch (error) {
        setInfoMsg({ isLoading: false, msg: "Indisponibil", status: "error" })
        console.log(error)
      }
    }
    getFaqs()
  }, [])

  const getContinutFaq = async itemId => {
    try {
      setInfoMsg({ isLoading: true, msg: "Vă rugăm așteptați" })
      const apiResponse = await getFaqById(itemId)
      if (apiResponse.type) {
        setSelectedFaq(apiResponse.data)
        setTabValue(apiResponse.data.tip)
      }
      setInfoMsg({ isLoading: false })
      // else console.log("TO DO: mesaj eroare")
    } catch (error) {
      setInfoMsg({ isLoading: false, msg: "Indisponibil", status: "error" })
      console.log(error)
    }
  }

  React.useEffect(() => {
    // if (window !== undefined && window.innerWidth > 850) {
    if (items.filter(item => item.tip === tabValue).length > 0) {
      const { idSelectedFaq } = getAllUrlParams(location.search)
      if (
        idSelectedFaq &&
        (selectedFaq ? idSelectedFaq !== selectedFaq._id : true)
      ) {
        getContinutFaq(idSelectedFaq)
        location.search = ""
      } else getContinutFaq(items.filter(item => item.tip === tabValue)[0]._id)
    } else setSelectedFaq(null)
    // }
  }, [items, tabValue, location.search])

  return (
    <TemplatePage
      additionalClassName="templateFullContainerFaqScreen"
      location={location}
      title={"Întrebări frecvente"}
      topPaneHeight={80 + 56}
      additionalTopPaneElement={
        <Tabs
          tabValue={tabValue}
          setTabValue={setTabValue}
          tabs={["Generale", "Gaze naturale"]}
        />
      }
      sideElement={
        <div className="faqSideMenu">
          <InfoMsg
            infoMsg={infoMsg}
            withSpinner
            containerClassName="infoLoadingContainer"
            textClassName="infoLoadingText"
          />
          {selectedFaq &&
            items
              .filter(item => item.tip === tabValue)
              .map((item, index) => (
                <div
                  className={
                    item._id === selectedFaq._id ? "item active" : "item"
                  }
                  onClick={() => getContinutFaq(item._id)}
                >
                  <span>{item.titlu}</span>
                </div>
              ))}
        </div>
      }
    >
      <Helmet>
        <title>Întrebări Frecvente - Gazmir</title>
        <meta
          name="description"
          content="Vezi raspunsul pentru Intrebari Frecvente ale clientilor Gazmir. Economiseste timp si afla informatii relevante despre serviciile Gazmir."
        />
        <meta
          name="keywords"
          content="Cum platesc factura gazmir,Cum imi fac cont pe gazmir.ro,Cum depun cerere gazmir.ro,Cum trimit autocitirea gazmir,Cand se factureaza gazmir,Cum factureaza gazmir,Factura gazmir,unde platesc factura gazmir,Cum platesc un preaviz gazmir,intrebari frecvente gazmir,Intrebari frecvente Gaz,Intrebari frecvente Gaze naturale"
        />
      </Helmet>
      {selectedFaq ? (
        <div className="faqContainer">
          <div className="faqTitle">
            <span className="verticalAlignTextInParent">
              {selectedFaq.titlu || "Indisponibil momentan"}
            </span>
            <span
              className="faqCloseIcon visibleOnTabletWithFlex"
              onClick={() => setSelectedFaq(null)}
            >
              {svgs.closeIcon}
            </span>
          </div>
          {selectedFaq.continut && (
            <div
              className="marginTop40"
              dangerouslySetInnerHTML={{
                __html: selectedFaq.continut,
              }}
            ></div>
          )}
        </div>
      ) : (
        <div className="visibleOnTabletWithFlex faqMobileList">
          {items
            .filter(item => item.tip === tabValue)
            .map((item, index) => (
              <div
                className="faqMobileListRow"
                onClick={() => getContinutFaq(item._id)}
              >
                <span className="faqMobileListText">{item.titlu}</span>
              </div>
            ))}
        </div>
      )}
      <InfoMsg
        infoMsg={infoMsg}
        withSpinner
        containerClassName="infoLoadingContainer"
        textClassName="infoLoadingText"
      />
      {/* {infoMsg && (
        <>
          <Spinner
            containerStyle={{
              width: 60,
              height: 60,
              margin: "200px auto 0px auto",
            }}
            show={infoMsg === "Vă rugăm așteptați"}
          />
          <div
            style={{
              padding: "20px 50px",
              // marginTop: 24,
              textAlign: "center",
              fontSize: 18,
              fontWeight: "bold",
              color: colors.main,
            }}
          >
            {infoMsg}
          </div>
        </>
      )} */}
    </TemplatePage>
  )
}
