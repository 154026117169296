import { ApiResponse, apiBase, customGet } from "./utils"

const baseUrl = `${apiBase}/api/faqs`

export type FaqItem = {
  _id: string
  titlu: string
  tip: number
  continut: string
}

export type FaqArray = Omit<FaqItem, "continut">[]

export function getAllFaqs(): Promise<ApiResponse<FaqArray>> {
  return customGet(`${baseUrl}/all`)
}

export function getFaqById(faqId: string): Promise<ApiResponse<FaqItem>> {
  return customGet(`${baseUrl}/${faqId}`)
}
